// @ts-nocheck
import React, { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import history from '@/asset/js/history';
import Loading from '@/component/loading';

const Home = lazy(() => import('@/page/home'));
const PrivacyPolicy = lazy(() => import('@/page/privacyPolicy'));
const TermsAndConditions = lazy(() => import('@/page/terms&Conditions'));
const About = lazy(() => import('@/page/about'));
const Report = lazy(() => import('@/page/report'));
const Feedback = lazy(() => import('@/page/feedback'));
const TotalRankVoice = lazy(() => import('@/page/totalRank'));
const GiftWall = lazy(() => import('@/page/giftWall'));
const GoldCoin = lazy(() => import('@/page/goldCoin'));
const Level = lazy(() => import('@/page/level'));
const AboutBadges = lazy(() => import('@/page/aboutBadges'));
const CoinMerchant = lazy(() => import('@/page/coinMerchant'));
const NewAristocracy = lazy(() => import('@/page/curAristocracy'));
const VipPrivileges = lazy(() => import('@/page/vipPrivileges'));
const VipHelp = lazy(() => import('@/page/vipHelp'));
const TotalRank = lazy(() => import('@/page/totalRankAll'));
const MyEarning = lazy(() => import('@/page/myEarning'));
const DiamondsFlow = lazy(() => import('@/page/diamondsFlow'));
const Share = lazy(() => import('@/page/share'));
const RankingList = lazy(() => import('@/page/rankingList'));
const LuckyPacket = lazy(() => import('@/page/luckyPacketHelp'));
const NewTask = lazy(() => import('@/page/newTask'));
const CommunityRules = lazy(() => import('@/page/communityRules'));
const NobleRules = lazy(() => import('@/page/nobleRules'));
const VipRules = lazy(() => import('@/page/vipRules'));
const SVipRules = lazy(() => import('@/page/sVipRules'));
const LuckyGiftList = lazy(() => import('@/page/luckyGiftList'));
const NewcomerGuide = lazy(() => import('@/page/newcomerGuide'));
const WithdrawBind = lazy(() => import('@/page/withdrawBind'));
const OfficialRule = lazy(() => import('@/page/officialRule'));
const TvWallRule = lazy(() => import('@/page/tvWallRule'));
const TiktokLogin = lazy(() => import('@/page/tiktokLogin'));
const Recharge = lazy(() => import('@/page/recharge'));
const RechargeResult = lazy(() => import('@/page/rechargeResult'));
const LumosPrivacyPolicy = lazy(() => import('@/page/lumosPrivacyPolicy'));

const authcheck = Com => {
  return (
    // <ErrorBoundary>
    <Suspense fallback={<Loading />}>
      <Com history={history} />
    </Suspense>
    // </ErrorBoundary>
  );
};

const suspense = (Com, params) => {
  return (
    <Suspense fallback={<Loading />}>
      <Com {...params} />
    </Suspense>
  )
}

const routeList = [
  {
    path: '/',
    element: <Navigate to='/home' />,
  },
  {
    path: '/lucky_gift_list/*',
    element: suspense(LuckyGiftList),
  },
  {
    path: '/termService',
    element: suspense(TermsAndConditions)
  },
  {
    path: '/coin_record',
    element: authcheck(GoldCoin),
  },
  {
    path: '/home_rank/*',
    element: suspense(TotalRankVoice),
  },
  {
    path: '/home_giftWall/*',
    element: authcheck(GiftWall),
  },
  {
    path: '/privacyPolicy',
    element: suspense(PrivacyPolicy),
  },
  {
    path: '/home',
    element: suspense(Home),
  },
  {
    path: '/AppInfoPage',
    element: suspense(About),
  },
  {
    path: '/userReport',
    element: suspense(Report, {type: 'user'}),
  },
  {
    path: '/roomReport',
    // element: <Report type='room' />,
    element: suspense(Report, {type: 'room'}),
  },
  {
    path: '/userFeedBack',
    element: suspense(Feedback),
  },
  {
    path: '/mine_level',
    element: authcheck(Level),
  },
  {
    path: '/mine_badges',
    element: authcheck(AboutBadges),
  },
  {
    path: '/mine_coins/*',
    element: suspense(CoinMerchant),
  },
  {
    path: '/new_aristocracy',
    element: suspense(NewAristocracy),
  },
  {
    path: '/vip_privileges',
    element: authcheck(VipPrivileges),
  },
  {
    path: '/vip_help',
    element: authcheck(VipHelp),
  },
  {
    path: '/home_rank_voice/*',
    element: authcheck(TotalRankVoice),
  },
  {
    path: '/mine_received',
    element: authcheck(MyEarning),
  },
  {
    path: '/mine_received_records/*',
    element: authcheck(DiamondsFlow),
  },
  {
    path: '/room_share',
    element: authcheck(Share),
  },
  {
    path: '/gift_ranking_list',
    element: authcheck(RankingList),
  },
  {
    path: '/packet_rule',
    element: authcheck(LuckyPacket),
  },
  {
    path: '/official_rules',
    element: authcheck(OfficialRule),
  },
  {
    path: '/home_rank_all/*',
    element: suspense(TotalRank),
  },
  {
    path: '/mine_task',
    element: suspense(NewTask),
  },
  {
    path: '/community_norms',
    element: suspense(CommunityRules),
  },
  {
    path: '/noble_rules',
    element: authcheck(NobleRules),
  },
  {
    path: '/vip_rules',
    element: authcheck(VipRules),
  },
  {
    path: '/svip_rules',
    element: authcheck(SVipRules),
  },
  {
    path: '/newcomer_guide',
    element: authcheck(NewcomerGuide),
  },
  {
    path: '/withdraw_bind',
    element: authcheck(WithdrawBind),
  },
  {
    path: '/tv_wall_rules',
    element: suspense(TvWallRule),
  },
  {
    path: '/tiktok_login',
    element: authcheck(TiktokLogin),
  },
  {
    path: '/recharge/*',
    element: suspense(Recharge),
  },
  {
    path: '/recharge_result',
    element: suspense(RechargeResult),
  },
  {
    path: '/lumos_privacyPolicy',
    element: suspense(LumosPrivacyPolicy),
  },
];

export default routeList;
