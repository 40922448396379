const TR = {
  'lucky.darw.ruls.1': 'Sohbet odalarından hediyeler gönderin, çekilişe katılma şansı yakalayın',
  'lucky.darw.ruls.2': 'Her 5000 coins hediye size 1 çekiliş hakkı verir',
  'lucky.darw.ruls.3': 'Örneğin: 50.000 coins = 10 çekiliş, 100.000 coins = 20 çekiliş. Daha fazla çekiliş için daha fazla hediye gönderin',
  'lucky.darw.ruls.4': 'Gün biterse ve çekilişlerinizi kullanmadıysanız, etkinlik bittikten sonra tüm çekilişleriniz 0 olur',
  'lucky.darw.number': 'çekilişlerimin sayısı',
  'lucky.darw.reset.time': 'SÜREYİ SIFIRLA',
  'lucky.darw.ranking': 'Çekiliş Sıralaması',
  'lucky.darw.use': 'KULLANMAYA GİT',
  'lucky.darw.no.times': 'Mevcut çekiliş sayısı yeterli değil, odaya gidin ve hediye gönderin',
  'lucky.darw.end': 'Etkinlik tamamlandı',
  'lucky.darw.coin.one.times': '1 defa çekme masrafı',
  'lucky.darw.coin.draw': 'Çek',
  'lucky.darw.coin.more.times': 'defa masrafı',
  'lucky.darw.coin.more.will.cost': 'tutacak',
  'fission.coins': 'coins',
  'common.recharge.text': 'Yeterli Coins’iniz yok. Yükleme yapmak ister misiniz? ',
  'fission.cancel': 'İptal',
  'lucky.darw.title': 'Şanslı Çekiliş',
  'gacha.play.3': 'defa',
  'gacha.play.1': 'aç',
  'gacha.play.2': 'defa',
  'common.cap.Congratulations': 'Tebrikler',
  'common.up.OK': 'TAMAM',
  'recharge.result.pending': 'İşlem tamamlandı',
  'recharge.result.tips': 'Coinsler zamanında ulaşmazsa, lütfen endişelenmeyin, ağ gecikmeleri olabilir. Herhangi bir sorunuz varsa, lütfen SOYO resmi müşteri hizmetleri ile iletişime geçin.',
  'recharge.result.tips.click': "APP'ye dönmek için tıklayın",
  '1': 'Ocak',
  '2': 'Şubat',
  '3': 'Mart',
  '4': 'Nisan',
  '5': 'Mayıs',
  '6': 'Haziran',
  '7': 'Temmuz',
  '8': 'Ağustos',
  '9': 'Eylül',
  '10': 'Ekim',
  '11': 'Kasım',
  '12': 'Aralık',
  'coin.recharge.modal.title': 'Ödeme Yöntemi',
  'coin.recharge.change.country': 'Ülke Seçin',
  'coin.recharge.current.country': 'Şu anda kullandığınız ödeme kanalı:',
  'ajax.timeout': 'Üzgünüz, bir şeyler ters gitti. Lütfen daha sonra tekrar deneyin.',
  'ajax.system': 'Üzgünüz, bir şeyler ters gitti. Lütfen daha sonra tekrar deneyin.',
  'ajax.network': 'Ağ bağlantısı yok. Lütfen daha sonra tekrar deneyin.',
  blank: '<p>İlgili içerik bulunamadı</p> <p>Lütfen diğer kanalları kontrol edin</p>',
  'pull.refresh.deactivate': 'Yenilemek için çekin',
  'pull.refresh.activate': 'Yenilemek için bırakın',
  'pull.refresh.release': 'Daha fazla yükleniyor',
  'push.refresh.activate': 'Daha fazla yükleniyor',
  'push.refresh.noMore': 'Daha fazla bilgi yok',
  'click.refresh': 'Yenilemek için tıklayın',
  'share.whatsapp.tip': 'WhatsApp telefonunuzda yüklü olmayabilir, yükleyin ve tekrar deneyin.',
  'share.ok': 'Tamam',
  'share.install': 'Şimdi Soundi Radio Uygulamasını Yükleyin',
  'share.download': 'İndir',
  'share.play': "Soundi Radio'da Dinle",
  'share.live': 'Canlı Yayın',
  'expection.oops': 'HATA...',
  'expection.homeBtn': 'Ana Sayfa',
  'ERR500.tips': 'Bir şeyler yanlış gitti. Geliştiriciler bu konuda bilgilendirildi ve sorunu düzeltecekler. Lütfen daha sonra tekrar deneyin. Teşekkür ederiz.',
  'err404.tips': 'Aranan haber mevcut değil. Lütfen diğer konuları kontrol edin. Sizi orada bekliyoruz!',
  commonErr: '<p>Sistem meşgul,</p><p>lütfen daha sonra tekrar deneyin</p>',
  loading: 'Yükleniyor...',
  retry: 'Tekrar dene',
  viewMore: 'Daha fazla görüntüle',
  more: 'daha fazla',
  'month.select': 'Ay Seçin',
  hot: 'Popüler',
  all: 'Tümü',
  'month.select.day': 'Tarih Seçin',
  'coin.merchant.title': 'CoinSatıcısı',
  'coin.merchant.set.password': 'Ticaret şifrenizi belirleyin',
  'coin.merchant.confirm.again': 'İşlem şifresini tekrar onaylayın',
  'coin.merchant.enter.password': 'İşlem şifrenizi girin',
  'coin.merchant.enter.original.password': 'Orijinal şifreyi girin',
  'coin.merchant.enter.new.password': 'Yeni şifreyi girin',
  'coin.merchant.confirm.new.again': 'Yeni şifreyi tekrar onaylayın',
  'coin.merchant.change.password': 'Şifre Değiştir',
  'coin.merchant.history.title': 'İşlem Geçmişi',
  'coin.merchant.history': 'Geçmiş',
  'coin.merchant.user': 'Kullanıcı',
  'coin.merchant.chat': 'Sohbet',
  'coin.merchant.coins.num': 'Madeni Para Envanteri:',
  'coin.merchant.transfer.coins': 'Madeni Para Transferi',
  'coin.merchant.enter.user.id': 'Kullanıcı kimliğini girin',
  'coin.merchant.search': 'Sorgulama',
  'coin.merchant.not.found': 'Kullanıcı bulunamadı',
  'coin.merchant.enter.amount': 'Miktarı girin',
  'coin.merchant.tips':
    'Kullanıcıya ek bir miktar transfer etmek üzeresiniz, lütfen adı ve madeni para miktarını tekrar onaylayın. Bu işlem geri alınamaz ve resmi olarak herhangi bir yanlış işlemden sorumlu değildir.',
  'coin.merchant.enter.transaction.password': 'İşlem şifresini girin',
  'common.cap.Confirm': 'Onayla',
  'coin.merchant.enter': 'Girin',
  'coin.merchant.to': 'Alıcı:',
  'coin.merchant.amount': 'Miktar:',
  'coin.merchant.id': 'Kimlik:',
  'coin.merchant.password.tips': 'Şifre en az 8 karakter olmalı ve büyük harf, küçük harf ve rakam içermelidir.',
  'coin.merchant.password.error': 'Yanlış Şifre. Lütfen tekrar deneyin.',
  'coin.merchant.success': 'Havale başarılı',
  'coin.merchant.list.title': 'Miktar seçin',
  'coin.merchant.list.desc': 'Bu kullanıcıya coins transferi, yüklemekle eşdeğerdir ve aynı zamanda VIP deneyimini artırır.',
  'coin.merchant.transfer': 'Transfer',
  'coin.merchant.recharge.title': 'CoinSatıcı\nŞarj',
  'coin.merchant.min.input': 'Minimum miktar 1000',
  'coin.merchant.der.channel': 'Ödeme Yöntemi:',
  'coin.merchant.der.channel.tips': 'Aşağıdaki yöntemlerle ödemeyi tamamlayamıyorsanız, lütfen resmi ekip WhatsApp hattıyla iletişime geçerek satın almayla ilgili bilgi alın:',
  'coin.merchant.der.channel.tips.link': 'kopyala',
  'coin.merchant.der.current.country': 'Şu anda kullanılan ödeme kanalı:',
  'coin.merchant.der.change.country': 'Ülke Seçin',
  'coin.merchant.der.modal.title': 'Ödeme Yöntemi',
  'common.cap.Recharge': 'Şarj',
  'coin.merchant.der.id': 'TalkTalk Kimliği',
  'coin.merchant.der.name': 'TalkTalk Takma Adınız:',
  'coin.merchant.der.error': '*Sonuç Bulunamadı',
  'coin.merchant.der.amount': 'Şarj Miktarı:',
  'coin.merchant.der.redeem': 'Kullanılabilir Puanlar:',
  'coin.merchant.der.fail': 'Şarj Başarısız oldu',
  'coin.merchant.der.fail.tips': 'Hesabınıza yükleme işlemi başarısız oldu, lütfen herhangi bir sorunuz varsa bize şu adresten ulaşın: TT_support@molelive.com',
  'coin.merchant.der.complete': 'Yükleme başarılı',
  'coin.merchant.der.complete.tips': '{number} coins {name} adlı hesabınıza başarıyla yüklendi! Daha fazla eğlence için giriş yapın!',
  'coin.merchant.der.another': 'Başka bir hesaba şarj yapın',
  'coin.merchant.der.more': 'Daha fazla yükleme yapın',
  'coin.merchant.der.problem': 'Sorunlarla mı karşılaşıyorsunuz?',
  'coin.merchant.der.bind.phone': 'Cep telefonu numarası bağlayın',
  'coin.merchant.der.sms.code': 'Doğrulama kodunu girin',
  'coin.merchant.der.sms.tips': 'Anormal bir etkinlik tespit edildi. Hesabınızın güvenliğini sağlamak için lütfen aşağıdaki doğrulama sürecini tamamlayın.',
  'coin.merchant.der.bind.phone.placeholder': 'Cep Telefonu Numarası',
  'coin.merchant.der.resend': 'Yeniden gönder',
  'common.cap.Continue': 'Devam et',
  'coin.merchant.der.code.tips': 'Eğer doğrulama kodunu alamıyorsanız, lütfen resmi ekibin WhatsApp hattıyla iletişime geçin: [WhatsApp numarası]',
  'total.rank.gift': 'WEEKLY<br />STAR',
  'total.rank.hour': 'Saatlik',
  'total.rank.day': 'Gün',
  'total.rank.week': 'Haftalık',
  'total.rank.month': 'Aylık',
  'total.rank.last.hour': 'Son Saat İLK 3',
  'total.rank.last.day': 'Son Gün İLK 3',
  'total.rank.last.week': 'Son Hafta İLK 3',
  'total.rank.last.month': 'Son Ay İLK 3',
  'total.rank.this.hour': 'Bu Saat',
  'total.rank.this.day': 'Bu Gün',
  'total.rank.this.week': 'Bu Hafta',
  'total.rank.this.month': 'Bu Ay',
  'total.rank.gap': 'Gap{X}',
  'total.rank.hide': 'Sıralamada Gizle',
  'total.rank.hide.name': 'Bay Gizemli',
  'total.rank.hide.info': 'Gizli Bilgiler',
  'total.rank.help.title': 'Sıralama Kuralları',
  'total.rank.help':
    '<h1>Sıralama Türü</h1>\n' +
    '<p>Sıralama &lt;Oda&gt;: Belirli bir zaman dilimi boyunca, tüm odalar, oda içinde gönderilen coins hediyelerine göre sıralanır.</p>\n' +
    '<p>Sıralama &lt;HEDİYECİ&gt;: Belirli bir zaman dilimi boyunca, tüm kullanıcılar, kullanıcı tarafından gönderilen coins hediyelerine göre sıralanır.</p>\n' +
    '<p>Sıralama &lt;BÜYÜLEYİCİ&gt;: Belirli bir zaman dilimi boyunca, tüm kullanıcılar, sizin aldığınız coins hediyelerine göre sıralanır.</p>\n' +
    '\n' +
    '<h1>Sıralama Zaman Döngüsü</h1>\n' +
    '<p>Sıralama döngüsü genellikle saatler, günler, haftalar ve aylar olarak belirlenir.</p>\n' +
    '<p>Sıralama, önceki döngünün en üst 3 sıralamasını ve güncel döngünün en üst 50 sıralamasını gerçek zamanlı olarak gösterir. (1 dakikadan az bir gecikme ile)</p>\n' +
    "<p>Yenileme Zamanı (UTC+3): Günlük sıralama her gün saat 5'te yenilenir; Haftalık sıralama her Pazartesi saat 5'te yenilenir; Aylık sıralama her ayın 1. günü saat 5'te yenilenir.</p>\n" +
    '\n' +
    '<h1>Gizlilik Koruma</h1>\n' +
    '<p>Bazı sıralamalarda belirli rakamlar gösterilmez;</p>\n' +
    '<p>Ancak, önceki sıralama ile arasındaki fark gösterilir;</p>\n' +
    '<p>En üst 3 sıralama arasındaki fark gösterilmez.</p>',
  'total.rank.help.eu':
    '<h1>Sıralama Türü</h1>\n' +
    '<p>Sıralama &lt;Oda&gt;: Belirli bir zaman dilimi boyunca, tüm odalar, oda içinde gönderilen coins hediyelerine göre sıralanır.</p>\n' +
    '<p>Sıralama &lt;HEDİYECİ&gt;: Belirli bir zaman dilimi boyunca, tüm kullanıcılar, kullanıcı tarafından gönderilen coins hediyelerine göre sıralanır.</p>\n' +
    '<p>Sıralama &lt;BÜYÜLEYİCİ&gt;: Belirli bir zaman dilimi boyunca, tüm kullanıcılar, sizin aldığınız coins hediyelerine göre sıralanır.</p>\n' +
    '\n' +
    '<h1>Sıralama Zaman Döngüsü</h1>\n' +
    '<p>Sıralama döngüsü genellikle saatler, günler, haftalar ve aylar olarak belirlenir.</p>\n' +
    '<p>Sıralama, önceki döngünün en üst 3 sıralamasını ve güncel döngünün en üst 50 sıralamasını gerçek zamanlı olarak gösterir. (1 dakikadan az bir gecikme ile)</p>\n' +
    "<p>Yenileme Zamanı (UTC+3): Günlük sıralama her gün saat 5'te yenilenir; Haftalık sıralama her Pazartesi saat 5'te yenilenir; Aylık sıralama her ayın 1. günü saat 5'te yenilenir.</p>\n" +
    '\n' +
    '<h1>Gizlilik Koruma</h1>\n' +
    '<p>Bazı sıralamalarda belirli rakamlar gösterilmez;</p>\n' +
    '<p>Ancak, önceki sıralama ile arasındaki fark gösterilir;</p>\n' +
    '<p>En üst 3 sıralama arasındaki fark gösterilmez.</p>',
  'common.cap.Ranking': 'Sıralama',
  'total.rank.room': 'ODA',
  'total.rank.sent': 'HEDİYECİ',
  'common.up.RECEIVED': 'BÜYÜLEYİCİ',
  'fission.relation.by.amount': 'Miktarına Göre',
  'fission.relation.by.time': 'Zamana Göre',
  'common.cap.Countdown': 'Geri Sayım',
  'common.up.RULES': 'KURALLAR',
  'common.up.DAYS': 'GÜNLER',
  'common.up.HOURS': 'SAATLER',
  'common.up.MINS': 'DAKİKALAR',
  'common.cap.Successfully.Collected': 'Başarıyla Toplandı',
  'common.cap.Countdown.to.Start': 'Başlamaya Geri Sayım',
  'common.cap.Day': 'Gün',
  'common.cap.Hour': 'Saat',
  'common.cap.Min': 'Dakika',
  'common.cap.Sec': 'Saniye',
  'common.cap.Rules': 'Kurallar',
  'common.cap.event.over': 'Etkinlik sona erdi',
  'recharge.event.tab.1': 'Şarj İlerlemesi',
  'recharge.event.amount.reached': 'Miktarı ulaşılan madeni paralar',
  'recharge.event.ranking.list': 'Sıralama Listesi',
  'recharge.event.group': 'Grup',
  'recharge.event.next.1': 'Sadece şu kadar madeni para gerekiyor',
  'recharge.event.next.2': 'Sonraki seviyeye ulaşmak için',
  'recharge.event.rewards.for': 'Ödüller',
  'recharge.event.trouble': 'Şarjda sorun mu yaşıyorsunuz?',
  'recharge.event.done': 'Tebrikler! En yüksek seviyeye ulaştınız! Şimdi ödüllerinizi alın!',
  'fission.trouble': 'Şarjda sorun mu yaşıyorsunuz?',
  'common.cap.No': 'Hayır',
  'common.cap.Unfortunately.Yes': 'Maalesef Evet',
  'common.cap.Waiting': 'Bekliyor',
  'common.cap.update': 'Yeni özellikleri kullanmak için sürümü güncelleyin',
  'common.cap.Customer.Server': 'Müşteri Hizmetleri',
  'common.cap.Ranking.click': 'Tıklamak',
  'common.cap.Ranking.click.text': 'Farklı ödülleri kontrol etmek için tıklayın',
  'common.cap.Collect': 'Topla',
  'common.cap.Collected': 'Toplandı',
  'common.cap.Limited.Gifts': 'Sınırlı Hediyeler',
  'family.football.target': 'Coins Ödül Hedefi',
  'dream.city.rule.reward': 'Kurallar ve Ödüller',
  'dream.city.city.map': 'Şehir Haritası',
  'dream.city.rules': 'Etkinlik Kuralları',
  'dream.city.supply.reward': 'Destek İstasyonu Ödülleri',
  'dream.city.wealth': 'Zenginlik',
  'dream.city.charm': 'Çekicilik',
  'dream.city.wealth.rank': 'Zenginlik',
  'dream.city.charm.rank': 'Çekicilik',
  'dream.city.current.coins': 'Güncel Coinsler',
  'dream.city.current.diamonds': 'Güncel Elmaslar',
  'dream.city.go': 'BAŞLA',
  'dream.city.will.get': 'Alacaksınız',
  'dream.city.received': 'Tebrikler! Aldınız',
  'dream.city.top5': 'En hızlı şekilde destek istasyonuna ulaşan ilk 5 kullanıcı, destek ödüllerini alacak.',
  'dream.city.waiting': 'bekliyor',
  'about.title': 'Hakkında',
  'Terms of Service': 'Kullanım Koşulları',
  'Privacy Policy': 'Gizlilik Politikası',
  'Return Policy': 'İade Politikası',
  'Intellectual Property Rights': 'Fikri Mülkiyet Hakları',
  'Contact Us': 'İletişim',
  'About Us': 'Hakkımızda',
  'Supported Payment Methods': 'Desteklenen Ödeme Yöntemleri',
  subTitle: 'Onaylanmış Tüccarlar Dışında Diğer Şarj Kanallarına Güvenmeyin.',
  chat: 'Sohbet',
  id: 'SOYO Kimliği',
  contact: 'İletişim',
  whatsapp: 'WhatsApp',
  right: 'SOYO ekibi en nihai yorum hakkını saklı tutar.',
  check: 'Şifre en az 8 haneli ve büyük/küçük harfler içermelidir.',
  same: 'Lütfen aynı şifreyi girin',
  copied: 'kopyalandı',
  details: 'Elmas Detayları',
  miss: 'Kaçırılan Elmaslar',
  profit: 'Gelir İstatistikleri',
  missToday: 'Kaçırılan-Bugün',
  cumulativeMiss: 'Kaçırılan-Toplam',
  distributionOfDiamondIncome: 'Elmas gelir dağılımı',
  getDiamondsMonth: 'Bu ayki elmaslar:',
  getDiamondsDay: 'Bugünkü elmaslar:',
  cancel: 'İptal',
  confirm: 'Onayla',
  daily: 'Günlük',
  month: 'Ay',
  tips: 'Daha fazla veri yok',
  voiceChat: 'Ankelle Sesli Sohbet için Kullanıcı Değerlendirmeleri',
  voiceChat1: 'Benimle Sesli Sohbet için Kullanıcı Değerlendirmeleri',
  quickCall: 'Ankelle Hızlı Arama için Kullanıcı Değerlendirmeleri',
  quickCall1: 'Benimle Hızlı Arama için Kullanıcı Değerlendirmeleri',
  chatting: 'Mesaj sohbet performansı',
  chatting1: 'Benim sohbet performansım',
  chatSpeed: 'Cevap hızı',
  chatSpeed1: 'Mesajlara cevap verme hızım',
  like: 'Beğen',
  boring: 'Sıkıcı',
  Hate: 'Nefret',
  chatConversation: '💬 Sohbet Turu',
  reached: '🙋‍♀️ Kullanıcı sayısı',
  count: '🙋‍♀️ Mesajlar',
  speed: 'Cevap hızı',
  quality: 'Sohbet kalitesi',
  call: 'Arama ve Değerlendirme',
  currentCycle: 'Güncel Döngü',
  speedTitle: 'Mesaja Cevap Verme Gelir Kuralları',
  roundTitle: 'Sohbet Turu Gelir Kuralları',
  modalTips: 'Mesajlara daha hızlı cevap vermeniz, kullanıcı deneyimini iyileştirecek ve daha fazla elmas kazanmanıza yardımcı olacaktır.',
  modalTipsTwo: 'Cevap içeriğinizin daha yüksek kalitesi ve daha fazla sohbet turu, kullanıcı deneyimini iyileştirecek ve daha fazla elmas kazanmanıza yardımcı olacaktır.',
  modalSpeed: 'Yanıt hızı',
  modalIncome: 'Gelir Oranı',
  modalRound: 'Sohbet turları',
  modalReward: 'Ekstra ödüller',
  modalBtn: 'Sohbete Devam Et',
  cycleDiamonds: 'Bu döngüdeki elmaslar',
  'terms.help': 'Uygulama Sorunları',
  'terms.bug': 'Hata',
  'terms.suggestions': 'Öneriler',
  'contacts.whatsapp': 'Whatsapp',
  'contacts.email': 'E-posta',
  'contacts.phone': 'Telefon',
  'submit.success': 'Geri bildiriminiz için teşekkür ederiz',
  'terms.title': 'Kategori',
  'details.placeholder': 'Lütfen sorununuzun detaylarını açıklayın',
  'contacts.title': 'Sizi nasıl iletişime geçebiliriz',
  'contact.placeholder': 'İletişim bilgilerinizi buraya bırakın [Gizli]',
  'submit.btn': 'Gönder',
  'country.select.hot': 'Popüler',
  'country.select.all': 'Tümü',
  'feedback.details': 'Detaylar',
  'feedback.screenshot': 'Ekran Görüntüsü (İsteğe Bağlı)',
  'feedback.title': 'Geri Bildirim',
  flowWater: 'Madeni Para Detayları',
  coinBack: 'İade Edilen Madeni Paralar',
  'home.recharge': 'Şarj',
  'home.slogan': 'Burada ruh eşinizi bulun<br />Her zaman yanınızda',
  'home.download': 'İndir',
  'income.rules.rules.1': "1. Maaş Kuralları: Her ayın 16'sı ve takip eden ayın 1'i 00:00'da hesaptaki elmas bakiyesinden kesilecektir.",
  'income.rules.rules.2': '2. Maaş Oranı: 1USD=0 elmas',
  'income.rules.rules.3': "3. Hesap Dönemi: ①Her ayın 16'sı 00:00② takip eden ayın 1'i 00:00",
  'income.rules.rules.4': '4. Hesap Dönemi: 7~14 iş günü (tatiller ertelenebilir)',
  'income.rules.chat': 'Müşteri Hizmetleri ile İletişim',
  'income.rules.important': 'Vurgu:',
  'income.rules.title': 'Maaş Kuralları',
  'income.rules.rules.extra':
    'Maaş ödendiğinde "SOYO Takımı" tarafından size bildirim yapılacaktır. Elmas kesinti ayrıntıları "Gelir" bölümünde kontrol edilebilir, lütfen SOYO Takımı bildirimlerine dikkat edin. Normal hesap dönemi genellikle 7-14 iş günü içinde tamamlanır, tatil günleri banka işlem süresini geciktirebilir, lütfen sabırlı olun ve ödemenizi bekleyin. Herhangi bir sorunuz varsa, lütfen SOYO Müşteri Hizmetleri ile iletişime geçin, desteğiniz için teşekkür ederiz!',
  'income.rules.usd': 'USD',
  'income.rules.diamonds': 'Elmaslar',
  'my.earning.title': 'Kazançlarım',
  'my.earning.tips': 'Elmas bakiyesi',
  'my.earning.daimonds': 'elmaslar',
  'my.earning.withdrawal.rules': 'Çekim Kuralları',
  'my.earning.salary.rules': 'Maaş Kuralları',
  'new.task.go': 'Git',
  'new.task.done': 'Tamamlandı',
  'task.center.title': 'Görev Merkezi',
  'recharge.result.success': 'Ödeme Başarılı',
  'recharge.result.error': 'Ödeme Başarısız',
  'common.up.OK': 'Tamam',
  'report.option.Porn': 'Pornografi',
  'report.option.Spam': 'Spam',
  'report.option.Fraud': 'Dolandırıcılık',
  'report.option.Violence': 'Şiddet',
  'report.option.Illegal': 'Yasa dışı',
  'report.option.Other': 'Diğer',
  'report.option.room': 'Bunu beğenmedim',
  'report.option.user': 'Kullanıcıyı beğenmedim',
  'report.reason': 'Kategori',
  'report.details': 'Detaylar',
  'report.details.placeholder': 'Raporunuzu ayrıntılı olarak açıklayın',
  'report.screenshot': 'Ekran Görüntüsü (İsteğe Bağlı)',
  'report.submit': 'Gönder',
  'report.submit.success': 'Başarıyla rapor edildi',
  'report.room': 'Rapor',
  'report.user': 'Kullanıcıyı Rapor Et',
  'retirar.rules.title': 'Çekim Kuralları',
  'retirar.rules.rules.1': '1. Çekim hizmetini kullanmadan önce, hesabınızın güvenliğini sağlamak için gerçek kimlik bilgilerinizi göndermeniz gerekmektedir.',
  'retirar.rules.rules.2': '2. Lütfen gerçek kimlik bilgileri sağlayın, bu bilgiler SOYO hesabınızın tanımlanması ve kullanma hakkının belirlenmesi için kullanılacaktır.',
  'retirar.rules.rules.3': '3. Gönderdiğiniz kimlik bilgileri kesinlikle gizli tutulacak ve diğer amaçlarla kullanılmayacaktır.',
  'retirar.rules.chat': 'Müşteri Hizmetleri ile İletişim',
  'retirar.rules.important': 'Vurgu:',
  'retirar.rules.rules.extra': 'Çekim hizmetini kullanmak için lütfen müşteri hizmetleriyle iletişime geçin ve aşağıdaki bilgileri sağlayın:',
  'retirar.rules.rules.extra.1': '1) Gerçek ad',
  'retirar.rules.rules.extra.2': '2) Whatsapp numarası',
  'retirar.rules.rules.extra.3': '3) Kimlik belgeleri (kimlik kartları, pasaportlar vb.)',
  'retirar.rules.rules.extra.4': '4) Ödeme yönteminiz (Banka Transferi, Payoneer, Fawry vb.)',
  'task.center.retirar': 'Şimdi Talep Et',
  'task.center.complete': 'Tamamlandı',
  'lucky.darw.end': 'Etkinlik devam etmiyor',
  'total.rank.daily': 'Günlük',
  'task.center.common.level': 'Seviye',
  'common.cap.Event.To.Start': 'Etkinlik Başlamak Üzere',
  'task.center.limit.task': 'Limited Task',
  'task.center.normal.task': 'Normal Task',
  'task.center.limit.today': 'Today',
  'task.center.limit.Total': 'Total',
  'task.center.limit.Diamonds.Received': 'Diamonds Received',
  'task.center.limit.Reward.Received': 'Reward Received',
  'task.center.limit.receive': 'Receive',
  'task.center.limit.extra': 'Extra',
  'task.center.limit.to.start': 'Event has not started yet',
  'gift.wall.title': 'Hediyeler',
  'gift.wall.help.text1': "Bir seferde {num} coins'i aşan hediyeler, hediye duvarında görünecektir",
  'gift.wall.help.text2': "Daha fazla coins'e sahip olan hediye ilk sıralamada gösterilecektir.",
  'gift.wall.help.text3': 'Hediye duvarındaki kayıt 24 saat boyunca saklanacaktır.',
  'level.user': 'Kullanıcı\nSeviyesi',
  'level.halo': 'Halo\nSeviyesi',
  'level.halo.question1.title': 'Yüksek Seviyenin Faydaları',
  'level.halo.question1.answer1': ' Daha Yüksek seviye , Daha Parlak Halo',
  'level.halo.question2.title': 'Seviye Simge Görüntüsü',
  'level.halo.question2.answer1': 'Seviye ne kadar yüksekse, simge o kadar benzersizdir',
  'level.halo.question3.title': 'Seviye Yükselme Ödülleri',
  'level.halo.question3.answer1': 'Harika Ödüller Almak İçin Seviye Atlayın',
  'level.halo.question4.title': 'Seviye Yükselmenin Yolları',
  'level.halo.question4.answer1': `1 coin değerinde hediye alın, 1 deneyim puanı kazanın.
   Deneyim ne kadar yüksekse, yükseltme o kadar hızlı olur.`,
  'level.user.question1.title': 'Yüksek Seviye Faydaları',
  'level.user.question1.answer1': 'Özel eşyalar ve ödüller alırsınız.',
  'level.user.question2.title': 'Seviye Atlamanın Yolları',
  'level.user.question2.answer1': 'Odada sohbet edin, oyun oynayın ve hediyeler gönderin',
  'level.user.question2.answer2': 'Günlük görevleri tamamlayın, seviye atlamak için deneyim kazanın',
  'level.user.question2.answer7': 'Odanızda Parti Yapın',
  'level.user.question2.answer8': "Coins'le hediyeler yollayın",
  'level.user.question2.answer9': 'Görevleri tamamlayın ve Deneyim Puanı toplayın',
  'level.user.question3.title': 'Seviye Simge Görüntüsü',
  'level.user.question3.answer1': 'Seviye ne kadar yüksekse, simge o kadar benzersizdir.',
  'level.user.question4.title': 'Seviye Atlama Ödülleri',
  'level.user.question4.answer1': 'Harika Ödüller Almak İçin Seviye Atlayın',
  'level.user.level': 'Seviye',
  'level.user.icon': 'Simge',
  'level.user.tips': 'Deneyim puanları kazanmaya devam ettikçe seviyeniz kademeli olarak artacaktır.',
  'level.user.way.up': 'Deneyim puanlarını artırmanın yolları:',
  'about.badges.title': 'Rozetler',
  'about.badges.0': 'Rozetleri Giymek',
  'about.badges.1': 'Tüm rozetler takılabilir, aynı anda 5 adet rozet takabilirsiniz..',
  'about.badges.2': 'Onur Rozeti',
  'about.badges.3': "Kümülatif aylık değerlere göre kilidi açılan rozetler, sahip olunsun veya olunmasın her ayın 1'inde sayılır. Geçerlilik süresi bir ay sürer.",
  'vip.privileges.title.privacy': 'Gizlilik ve Güvenlik',
  'vip.privileges.label.privacy': 'Diğerleri seni/senin odanı arayamaz',
  'vip.privileges.title.invisible': 'Görünmez Mod',
  'vip.privileges.label.invisible': 'Görünmez kalın ve kendinizi başkaların tavsiyelerinden gizleyin',
  'vip.privileges.title.blockMessages': 'Sessiz Mod',
  'vip.privileges.label.blockMessages': 'Yabancıların mesajlarını engelle ve rahatsız edilme',
  'vip.privileges.title': 'VIP Ayrıcalıkları',
  'vip.privileges.activate': 'VIP{N} Etkinleştir',
  'vip.privileges.stealth.text': 'Erişim geçmişimi gizle',
  'vip.privileges.stealth.open': 'Aç',
  'vip.privileges.stealth.close': 'Kapat',
  'vip.privileges.title.icon': 'VIP etiketi',
  'vip.privileges.label.icon': 'Mükemmel vip etiketi, özel vip kullanıcı statüsü, itibarınızı göstermek için sizinle her yerde',
  'vip.privileges.title.visitor': 'Ziyaretçi Geçmişi',
  'vip.privileges.label.visitor': 'Tüm ziyaretçi geçmişini görün, sizinle ilgili kişileri ve hayranları kaçırmazsınız',
  'vip.privileges.title.list': 'Öncelikli görüntüleme',
  'vip.privileges.label.list': 'En iyi arama sonuçlarında öncelikli görüntüleme',
  'vip.privileges.title.follower': 'Takipleri yükselt',
  'vip.privileges.label.follower':
    'Daha fazla arkadaş takip edebilirsiniz: VIP olmayan biri 500 kişiyi takip edebilir, vip1 1000 kişiyi takip edebilir, vip4 2000 kişiyi takip edebilir, vip7 3000 kişiyi takip edebilir',
  'vip.privileges.title.sign': 'VIP giriş hediyesi',
  'vip.privileges.label.sign': "VIP'ler, oturum açma hediyelerinden iki kat yararlanabilir",
  'vip.privileges.title.stealth': 'Görünmez erişim',
  'vip.privileges.label.stealth': 'Diğer kullanıcıları ziyaret ettiğinizde geçmiş kaydedilmez',
  'vip.privileges.title.name': 'Kullanıcı adı vurgusu',
  'vip.privileges.label.name': 'Kullanıcılar, kullanıcı adını süslemek için özel efektler alır',
  'vip.privileges.title.avatar': 'VIP profil çerçevesi',
  'vip.privileges.label.avatar': 'Özel vip avatarı',
  'vip.privileges.title.bubble': 'VIP özel sohbet balonu',
  'vip.privileges.label.bubble': 'Özel vip sohbet balonları',
  'vip.privileges.title.room': 'VIP oda kartı',
  'vip.privileges.label.room': 'Özel vip oda kartı',
  'vip.privileges.title.picture': 'Resimlerle sohbet et',
  'vip.privileges.label.picture': 'bubble sohbette resim gönderme seçeneği',
  'vip.privileges.title.message': 'Mesajları üste sabitle',
  'vip.privileges.label.message': 'Diğer kullanıcıların sohbetini mesajın üstüne sabitleme imkanı',
  'vip.privileges.title.vehicle': 'ViP Araçlar',
  'vip.privileges.label.vehicle': 'Özel VIP araçlar',
  'vip.privileges.title.rank': 'Sıralamada Gizem',
  'vip.privileges.label.rank': 'Sıralamalarda Gizemli olarak görünmek için görünmezlik anahtarını açabilirsiniz',
  'vip.privileges.title.broadcast': 'Platform genelinde gönderilen yükseltme bildirimi',
  'vip.privileges.label.broadcast': 'Vip8 veya üstüne ulaştığınızda yükseltme bildirimleri platform genelinde gönderilebilir',
  'vip.privileges.title.id': 'Benzersiz kişisel kimlik',
  'vip.privileges.label.id':
    'Benzersiz kişisel kimliğinizi alın ve başkalarının sizi bir bakışta hatırlamasına izin verin! Benzersiz numaranızı almak için VIP müşteri hizmetlerinize başvurmanız gerekir. Sayı formatı (vip8: 6 haneli ABABAB. vip9: 6 haneli AAABBB; vip10: 4 haneli ABAB; vip11: 4 haneli ABAB. (vip11:4 haneli AABB). Bıraktıktan sonra geri çağır.',
  'vip.privileges.title.protection': 'VIP Koruması',
  'vip.privileges.label.protection': 'Sohbetten yasaklanma ve odalardan çıkarılma konusunda asla endişelenmeyin',
  'vip.privileges.title.gif': 'Özel GIF profil çerçevesi',
  'vip.privileges.label.gif':
    "Farkınızı göstermek için kendi özel GIF profil çerçevenizi özelleştirin! VIP9'a ulaştığınızda, özel GIF profil çerçevenizi almak için VIP müşteri hizmetleri ile iletişime geçmeniz gerekecektir. kullanıcı başına yalnızca bir kez kullanılabilir.",
  'vip.privileges.title.gift': 'Kişiye Özel Hediyeler',
  'vip.privileges.label.gift': `Seçtiğiniz kişi için özel hediyenizi özelleştirin!
  VIP10'e ulaştığınızda, kişiye özel bir hediye almak için VIP müşteri hizmetleri ile iletişime geçmeniz gerekir, her kullanıcının yalnızca bir kişiselleştirme şansı vardır.`,
  'vip.privileges.title.exclusivevehicles': 'Özel araçlar',
  'vip.privileges.label.exclusivevehicles': `Özel araçlarınızı benzersiz giriş efektleriyle özelleştirin! VIP11'e ulaştığınızda.
  Kullanıcı başına yalnızca bir kez kullanılabilen özel araçlara sahip olmak için VIP müşteri hizmetleri ile iletişime geçmeniz gerekir.`,
  'vip.privileges.title.open': 'Özel açılış sayfası',
  'vip.privileges.label.open':
    "Uygulamayı açın ve tüm ihtişamınızla siz olun! VIP12'ye ulaştığınızda, her kullanıcı için yalnızca bir kez kullanılabilen 24 saat özel kişiselleştirilmiş açılış sayfası almak için VIP müşteri hizmetlerinizle iletişime geçmeniz gerekir.",
  'vip.privileges.update.info': 'Vip sistemi güncellendi, sürümünüz en son özellikleri görüntülemek için çok düşük. Lütfen en son sürüme güncelleyin ve yeni vip işlevinin keyfini çıkarın!',
  'aristocracy.vip.title': 'Aristokrasi',
  'vip.help.1': 'VIP Kuralları',
  'vip.help.2': 'VIP nedir',
  'vip.help.3': "VIP, SOYO'ta özel bir durumun somutlaştırılmış halidir. VIP sahibi olduktan sonra ilgili onur ve avantajları alabileceğiniz 11 statü seviyesi içerir",
  'vip.help.4': 'Vip seviyesi nasıl yükseltilir',
  'vip.help.5': `Kullanıcılar, her 1 $'lık yeniden yükleme için 1 VIP deneyimi alacaktır.</br>
  Kullanıcılar, birikmiş VIP deneyim puanları o aydaki yükseltme deneyim puanına ulaştığında VIP seviyelerini hemen yükselteceklerdir.</br>
  VIP olduktan sonraki her ayın ilk gününde, kullanıcının bir önceki aya ait birikmiş deneyimi silinecektir.
  Ve kullanıcının VIP seviyesi, kullanıcının bir önceki ay birikmiş deneyimine göre yeniden düzenlenecektir.`,
  'vip.help.6': 'VIP seviyesi yükseltme kuralları',
  'vip.help.7': 'Yükseltme kuralları',
  'vip.help.8':
    "Bu ay için yükseltme kriterlerine ulaştığınızda, VIP seviyeniz hemen güncellenecek ve VIP seviyeniz bir sonraki ayın sonuna kadar korunacaktır. Örneğin, bir kullanıcı 5 Haziran'da VIP4'e ulaşırsa, VIP4'ü 31 Temmuz'a kadar sürer",
  'vip.help.9': 'VIP Dondurma Kuralları',
  'vip.help.10':
    "Birikmiş exp değeriniz bu ay mevcut VIP seviyeniz için gerekli exp değerine ulaşırsa, bir sonraki ay VIP seviyenizi koruyacaksınız; aksi takdirde VIP seviyeniz bir sonraki ay düşürülecektir. Örneğin: Mayıs'ta VIP5 iseniz, ancak Mayıs'ta yalnızca VIP3 exp değeri biriktirdiyseniz, VIP seviyeniz Haziran'ın ilk günü VIP3'e düşürülecektir",
  'vip.help.11': 'VIP seviyesi indirimi',
  'vip.help.12': "Her ayın 1'inde, her ayın ilk günü sıfırlanmadan önce VIP düzeyine göre mevcut kullanıcının VIP düzeyi exp değerine göre belirli bir miktar VIP iade edilir",
  'vip.help.13': 'Seviye',
  'vip.help.14': 'Exp değeri aralığı',
  'vip.help.15': 'Experience of exp',
  'vip.help.update.info': 'Vip sistemi güncellendi, sürümünüz en son özellikleri görüntülemek için çok düşük. Lütfen en son sürüme güncelleyin ve yeni vip işlevinin keyfini çıkarın!',
  'to.exchange.page.button': 'Değişim',
  'ranking.daily': 'Günlük',
  'ranking.weekly': 'Haftalık',
  'ranking.contributed': 'Katkıda bulundu:',
  'ranking.blank.daily': 'Bugün kimse hediye göndermedi',
  'ranking.blank.weekly': 'Bu hafta kimse hediye göndermedi',
  'lucky.packet.title': 'Şanslı Paket',
  'lucky.packet.1': 'Şanslı Paket Gönder',
  'lucky.packet.2': 'Odadaki tüm kullanıcılar şanslı paket gönderebilir ve talep edebilir',
  'lucky.packet.3': 'Bir odada çok sayıda şanslı paket olduğunda, bunlar saklanacaktır ve görüntülenecektir. Şanslı paketler ne kadar erken gönderilirse, üstte o kadar çok gösterilir.',
  'lucky.packet.4': 'Kısıtlamalar ve Koşullar',
  'lucky.packet.5': 'Yalnızca Coins destekler',
  'lucky.packet.6':
    'Varsayılan limit 3000 coins, minimum limit 1000 coins ve maksimum limit 9999999 coinstir. İlk varsayılan şanslı paket sayısı 10 kişi, minimum sayı 5 kişi ve maksimum sayı 500 kişidir.',
  'lucky.packet.7': 'The amount per user (amount of coins/number of people) needs to be more than or equal to 100',
  'lucky.packet.8': "Paket miktarı 10000 coins'ten fazla olduğunda ve katılımcı sayısı 10'den fazla olduğunda, bu bir uçan bildirimi tetikleyecek ve oda listesinde özel bir simge görüntülenecektir.",
  'lucky.packet.9': 'Nasıl Oynanır',
  'lucky.packet.10': '"Password"Lucky packet - Almak için şifreyi odaya göndermeniz gerekiyor.',
  'lucky.packet.11': '"Follow"Lucky packet -Almak için şanslı paketi gönderen kullanıcıyı takip etmeniz gerekir.',
  'lucky.packet.12': 'Genel Problem',
  'lucky.packet.13': 'Paket uçuş bildirimini tetiklediğinde veya şanslı paket simgesi görüntülendiğinde, diğer kullanıcılar buna tıklayarak odanıza girebilir',
  'lucky.packet.14': 'Eğer odanız kilitliyse, bu uçan bildirimi veya diğer harici görüntü efektlerini tetiklemez',
  efficient_text_2: 'Etkili zaman',
  efficient_text_5: 'Etkili gün',
  efficient_text_6: '1. Odada geçerli zaman',
  efficient_text_7:
    'Ses odasında, mikrofondaki zaman - mikrofondan çıkma süresi> = %$ dakika mikrofon üzerinde etkili bir zamandır. Mikrofondaki kişiyi uzun süre iyi arkadaşlarla sohbet etme ve etkileşim kurma inisiyatifine katılmaya teşvik ediyoruz, örneğin:',
  efficient_text_8: 'Mikrofonda Zaman',
  efficient_text_9: 'Mikrofon kapalı zaman',
  efficient_text_10: 'Standarda ulaşıp ulaşmadığı',
  efficient_text_11: 'Açıklama',
  efficient_text_12: 'Standarda ulaşır',
  efficient_text_13: 'Standarda ulaşmadı',
  efficient_text_14: 'Mikrofondaki zaman ertesi güne kadar sürerse, bir önceki günün zamanı olarak sayılır',
  efficient_text_15: 'Mikrofonun kalması -Mikrofon <%$ dakika.',
  efficient_text_16: 'Mikrofondaki mikrofon zamanının süresi> = %$ dakika',
  efficient_text_17: '12/1 9:00:00 Mikrofonda zaman standarda ulaşmadı, etkili bir süre olarak sayılamaz',
  efficient_text_18: '12/1 23:00:00 Mikrofonda 12/2 kadar devam etti ve arka arkaya 90 dakika sürdü, bu yüzden 12/1 sırasında zaman olarak sayılır',
  efficient_text_19: '2. Odanın etkili günleri',
  efficient_text_20:
    'Ses odasında, tüm etkili süreler gün boyunca eklenir> = %$ dakika etkili bir gündür. Geçerli gün sayısı ne kadar yüksek olursa, faaliyet o kadar yüksek olur ve aktivite oranı, elde ettiğiniz ödülleri doğrudan etkiler.',
  efficient_text_21: 'Toplam Etkili Süre',
  efficient_text_22: 'Gün boyunca mikrofonda 12/1 3 kez: 15 dakika +35 dakika +90 dakika. 15 dakika standardı karşılamadığından, geçerli zaman = 90 +35 = 125 dakika = 02h 05 dakika',
  efficient_text_23: '12/1 Toplamda Günün Geçerli Süresi = 02h 05min> = %$ dakika, geçerli bir gün olarak sayılır',
  efficient_text_24: '3. Mikrofonda zaman',
  efficient_text_25:
    'Mikrofondaki zaman etkili bir zaman olmalı ve etkili zaman> = %$ dakika olmalıdır. Mikrofondaki zaman yalnızca odadaki mikrofonda sohbet etme süresini gösterir. Mikrofondaki zaman> = %$ dakika gibi etkili bir zaman olarak kabul edilecektir, örneğin:',
  efficient_text_26: '4. Veri Ayrıntıları',
  efficient_text_27: 'Veriler seçilen döngüye göre görüntülenecektir',
  efficient_text_28: 'Elmas',
  efficient_text_29: 'Sohbet',
  efficient_text_30: 'Ses Odası',
  efficient_text_31: 'Detaylar',
  efficient_text_32: 'Tarih',
  efficient_text_33: 'Mikrofonda Zaman',
  efficient_text_34: 'Ses odasının etkili zamanı',
  efficient_text_35: 'Ses odasının etkili günleri',
  share_text23: 'indirmek',
  share_text24: 'Odaya Katılın',
  share_text25: 'Bu odadayım, gel ve bize katıl',
  share_text26: 'Oda kimliği',
  OfficialTask_Card_common_text1: 'Resmi görev',
  OfficialTask_Card_common_text2: 'Resmi ödül görevini tamamladıktan sonra maksimum {%s} elmas alabilirsiniz!',
  OfficialTask_dailyCard_text1: 'Günlük elmas geliri ödülleri ({%s1}/{%s2})',
  OfficialTask_dailyCard_text2: "Günlük elmas geliri ({%s1}) {%s2} 💎'ye ulaştı, {%s3} 💎 alın",
  OfficialTask_dailyCard_text3: "Günlük elmas geliri ödülleri ({%s3}) {%s1} 💎'ye ulaştı, {%s2} 💎 kazanın",
  OfficialTask_dailyCard_text4: 'Ödül Zamanı',
  OfficialTask_dailyCard_text5: 'İLERİ',
  OfficialTask_PeriodCard_text1: 'Çalışma Dönemi Elmas Ödülü ({%s1}/{%s2})',
  OfficialTask_PeriodCard_text2: "Çalışma dönemi elmas ödülü ({%s3}) {%s1} 💎'ye ulaştı, {%s2} alın 💎",
  OfficialTask_ActiveCard_text1: 'Çalışma dönemi aktif ödülü',
  OfficialTask_ActiveCard_text2: '{%s1} tian ({%s2}/{%s3}) döngüsünde kümülatif giriş',
  OfficialTask_ActiveCard_text3: "Günlük elmas geliri {%s1} 💎'ye ulaşırsa, {%s2} 💎 alabilirsiniz. (Bugün: {%s3}/{%s4})",
  OfficialTaskPic_Level_text1: 'Günlük Elmas Geliri Ödülleri (IM Mesajı)',
  OfficialTaskPic_Level_text2: 'seviye',
  OfficialTaskPic_Level_text3: 'Elmas Hedefi',
  OfficialTaskPic_Level_text4: 'Elmas ödülü',
  OfficialTaskPic_Level_text5: 'Günlük Elmas Gelir Ödülleri (Ses odası hediyeleri: normal ses odası + aile ses odası)',
  OfficialTaskPic_Level_text6: 'Çalışma Dönemi Elmas Gelir Ödülleri (IM mesajı + alınan tüm hediyeler)',
  OfficialTaskPic_Level_text7: 'Çalışma Dönemi Aktif Ödüller',
  OfficialTaskPic_Level_text8: '{%s1} gün boyunca giriş yapın ve her gün {%s2} elmas kazanın',
  OfficialTaskPic_Level_text9: 'Gereklilik',
  OfficialTaskPic_Level_text10: 'Elmas ödülü',
  OfficialTaskPic_Level_text11: 'Bildirim:',
  OfficialTaskPic_Level_text12: '1. Ses odası hediyesi: normal ses odası ve aile ses odası dahil;',
  OfficialTaskPic_Level_text13: '2. Çalışma dönemi aktif ödülü: Dönem bittikten sonra aktif günlerin sayısı sayılacak ve alabileceğiniz maksimum ödül 350 elmastır;',
  OfficialTaskPic_Level_text14: '3. Tüm elmas ödülleri bu çalışma döneminde doğrudan hesabınıza gönderilecektir;',
  OfficialTaskPic_Level_text15: '4. Her 80 elmas = 1 USD',
  OfficialTaskPic_Level_text16: 'Günlük ödül (sohbetlerdeki hediyeler, sesli ve görüntülü aramalar, sesli eşleşme için)',
  OfficialTaskPic_Level_text17:
    'Sohbetler, ses ve video görüşmeleri, ses eşleştirme ve günlük hedefe ulaşma hediyeleri için elmas alırsanız, ilgili elmas ödülünü alırsınız. Ertesi gün otomatik olarak elmas ödüller eklenecektir.',
  OfficialTaskPic_Level_text18: 'Günlük Ödül (sohbetler ve ses odalarındaki hediyeler için)',
  OfficialTaskPic_Level_text19: 'Çalışma Dönemi Ödülü (ses ve video görüşmeleri için)',
  OfficialTaskPic_Level_text20:
    '1. Sohbetlerde ve ses odalarında hediyeler için elmas alırsanız ve günlük hedefi karşılıyorsanız, ilgili elmas ödülünü alırsınız. Ertesi gün otomatik olarak elmas ödüller eklenecektir.',
  OfficialTaskPic_Level_text21:
    '2. Ses ve video görüşmeleri için elmas alırsanız ve çalışma döneminin sonuna kadar hedefi karşılıyorsanız, ilgili elmas ödülünü alırsınız. Elmas ödülleri, çalışma döneminin bitiminden sonraki gün otomatik olarak eklenecektir.',
  OfficialTaskPic_Level_text22: "3. Her ayın 1. ila 15'inden 15'e ve her ayın 16. ila 30./31'leri arasında süren 2 çalışma süresi vardır.",
  OfficialTaskPic_Level_text23: 'Günlük Ödül 1 (Sesli Parti Odası ve Aile Hediyesi Elmas)',
  OfficialTaskPic_Level_text24: 'Günlük Ödül 2 (sesli çağrı, sesli eşleşme elmas)',
  OfficialTaskPic_Level_text25: 'Çalışma Dönemi Ödülü (Sohbet, Sesli Parti Odası, Sesli Aramalar, Sesli Eşleştirme)',
  OfficialTaskPic_Level_text26:
    '1. Sohbetlerde, sesli oda ve görüntülü aramalarda, sesli eşleşmede hediyeler karşılığında elmas alırsanız ve haftalık hedefi karşılarsanız, ilgili elmas ödülünü alacaksınız. Elmas ödülleri size eklenecek',
  OfficialTaskPic_Level_text27: '2. Maaş oranı: 80 elmas = 1 USD',
  OfficialTaskPic_Level_text28: '3. Günlük ödüllerden elde edilen elmaslar bu elmas hedefine dahil edilmeyecektir;',
  OfficialTaskPic_Level_text29: 'Soyo Resmi Ödül Sistemi 02.2024',
  OfficialTaskPic_Level_text30: 'Günlük Görev 1: Tüm Aramalar + Tüm Hediyeler',
  OfficialTaskPic_Level_text31: 'Günlük Görev 2: Mesaj Cevabı',
  OfficialTaskPic_Level_text32: 'Günlük Elmas Hedefi',
  OfficialTaskPic_Level_text33: 'Günlük elmas hedefimiz yukarıda gösterilmiştir ve ödül sistemimizdeki bir gün Türk yerel saatine dayanmaktadır.',
  cms_report_text3: 'Rapor Nedeni:',
  cms_report_text4: 'Rapor Ayrıntıları:',
  cms_report_text5: 'Rapor Ekran görüntüsü:',
  cms_report_text6: '*Lütfen yukarıdaki bilgileri doğrulayın, böylece bilgi cezası uygulayabiliriz.',
  cms_report_text7: 'Reporter Showid:',
  cms_report_text8: 'The Reporter Showid:',
  cms_report_text9: 'Ceza yöntemi:',
  cms_report_text10: 'Ceza sonucu:',
  cms_report_text11: 'Ceza geçmişi',
  cms_report_text12: 'Sözlü uyarı',
  cms_report_text13: 'Yasaklama yöntemi:',
  cms_report_text14: 'Yasaklama nedenleri:',
  cms_report_text15: 'Yasaklanma süresi:',
  cms_report_text16: 'Siyasi hassasiyet',
  cms_report_text17: 'Hakaret',
  cms_report_text18: 'Saldırı ve Kötüye Kullanım',
  cms_report_text19: 'Kanlı şiddet',
  cms_report_text20: 'Reklam',
  cms_report_text21: 'Şüpheli Dolandırıcılık',
  cms_report_text22: 'Yasadışı bilgi',
  cms_report_text23: 'Küçük',
  cms_report_text24: 'diğer',
  cms_report_text25: 'Onayla',
  cms_report_text26: 'Hesaba duyarlı işlem, lütfen bilgilerden sonra bilgileri onaylayın.',
  OfficialTask_dailyCard_text6: 'tamamlandı',
  OfficialTask_rewardTime_text1: 'saat',
  OfficialTask_rewardTime_text2: 'dakikalar',
  delete_account_web_title: 'Sevgili SOYO kullanıcısı. Ürünümüzü kullandığınız ve desteklediğiniz için teşekkürler. Hesabınızı silmek isterseniz, lütfen işlem için aşağıdaki adımları kontrol edin:',
  delete_account_web1: '1. Uygulamayı açın ve ana sayfayı girin, bana girmek için sağ altta tıklayın.',
  delete_account_web2: '2. Bana girdikten sonra ayarları tıklayın.',
  delete_account_web3: "3. Ayarları girdikten sonra Hesabı Sil'i tıklayın.",
  delete_account_web4: '4. Hesabı kalıcı olarak silmek için ilk adımı onaylayın.',
  delete_account_web5: '5. İkinci adımı onaylayın için aynı hesabı kullanamaz.',
  delete_account_web6: '6. İlgili tüm kişisel bilgileri silmek için son adımı onaylayın.',
  delete_account_web7:
    '7. Yukarıdaki tüm adımları bitirdikten sonra. Hesabınız, tüm kişisel bilgileriniz ve hesap verilerinizle başarıyla silinmiştir. Platformumuz artık verilerinizi veritabanımızda tutmayacak.',
  delete_account_web: 'Hesabı Sil',
  svip_introduce_1: '1.SVIP nedir',
  svip_introduce_text1: 'SVIP platformun seçkin seviye sistemidir. Toplamda 8 seviye vardır, SVIP1-SVIP8. Seviye ne kadar yüksek olursa, statü de o kadar seçkin olur ve sahip olduğunuz haklar ve çıkarlar da o kadar gelişmiş olur.',
  svip_introduce_2: '2. Nasıl SVIP olunur?',
  svip_introduce_text2: 'Coins yükleyerek SVIP deneyim puanı kazanabilirsiniz, 1 coins = 1 deneyim puanı. Deneyim puanları farklı SVIP seviyelerine ulaştığında, ilgili SVIP seviyesini elde edebilirsiniz.',
  svip_introduce_3: "3. SVIP'in geçerlilik süresi nasıl hesaplanır?",
  svip_introduce_text31: "SVIP her olağan ayı bir döngü olarak ele alır ve her ayın 1'inde SVIP deneyim puanları 0'dan toplanır. İlgili SVIP düzeyi, SVIP deneyim değerinin ulaştığı seviyeye göre elde edilecektir.",
  svip_introduce_text32: "SVIP statüsünü aldığınız andan itibaren statünüz ve ayrıcalıklarınız bir sonraki ayın sonuna kadar korunacaktır. (Örneğin: 18 Mayıs'ta SVIP3'e ulaşılırsa SVIP3 durumu 30 Haziran'a kadar uzatılacaktır.)",
  svip_introduce_text33: "SVIP deneyim değeriniz bir ay içinde daha yüksek bir seviyeye ulaşırsa hemen yükseltilecektir. (Örneğin: 18 Mayıs'ta SVIP3'e ve 25 Mayıs'ta SVIP4'e ulaşırsanız, 25 Mayıs'ta hemen SVIP4 statüsüne kavuşursunuz ve 30 Haziran sonuna kadar devam edersiniz.)",
  svip_introduce_text34: "Ay sona erdiğinde kimlik ve ayrıcalıklar korunacak ancak bir sonraki aya ait SVIP deneyim puanları 0'dan toplanacaktır. (Örneğin: 25 Mayıs'ta SVIP4'e ulaşırsanız, kimlik ve haklardan yararlanmaya devam edeceksiniz.) Haziran ayı boyunca SVIP4, ancak Haziran ayında 0'dan itibaren SVIP deneyim puanları biriktirmeye başlayacaksınız)",
  svip_introduce_4: "4.SVIP'in düşmesi ve puanların düşmesi",
  svip_introduce_text41: "Her ayın başında, SVIP seviyeniz varsa ve bu SVIP seviyenizi bir sonraki ayda da korumak istiyorsanız, doğal ayın sonundan önce SVIP seviyesi deneyim değerinin %50'sine ulaşmanız gerekir; Önümüzdeki ay bu seviyeyi koruyabilir, kimliğini ve haklarını koruyabilir. (Örneğin: Mayıs başladığında SVIP4 olursunuz, o zaman SVIP4 seviyesini Haziran sonuna kadar korumak için Mayıs ayı boyunca SVIP4 seviyesinin deneyim değerinin %50'sine ulaşmanız yeterlidir.)",
  svip_introduce_text42: "Olağan bir ay içinde SVIP seviyesi deneyim değerinin %50'sine ulaşılamazsa, bir sonraki ayda 1 seviye düşürülecektir. (Örneğin: Mayıs ayının başında SVIP4'sünüz ve Mayıs ayının tamamındaki deneyim değeriniz SVIP4 seviyesinin %50'sine ulaşmıyorsa, Haziran ayı SVIP3'ten başlayarak 1 seviye düşecektir.)",
  noble_introduce_title: 'Aristokrasi Kurallarına Giriş',
  noble_introduce_welcome: 'Aristokratik sistemimize hoş geldiniz! Aristokrat olmak, daha fazla benzersiz ayrıcalık ve avantajlardan yararlanmanıza olanak tanıyacaktır. Aşağıdakiler aristokratik sistemimize faydalı bir giriş niteliğindedir:',
  noble_introduce_1: '1.Aristokrasi Nedir?',
  noble_introduce_text1: 'Aristokrasi özel bir statü sembolüdür ve Şövalye, Baron, Vikont, Kont, Marki, Dük ve Kral olmak üzere yedi statü seviyesine bölünmüştür. Farklı aristokrasi düzeyleri farklı ayrıcalıklara sahiptir.',
  noble_introduce_2: '2. Nasıl Aristokrat Olunur?',
  noble_introduce_text2: 'Aristokrasi, coins yüklenerek satın alınabilir ve satın alma işlemi, Aristokrasi sayfasında ilgili seviye seçilerek tamamlanabilir. Aynı zamanda başkalarından alacağınız hediyelerle de Aristokrat statüsü elde edebilirsiniz.',
  noble_introduce_3: '3. Geçerlilik Süresi Hesaplaması',
  noble_introduce_text3: 'Aristokrat statüsü, satın alma tarihinden itibaren 30 gün süreyle geçerlidir ve geçerlilik süresi boyunca Aristokrat ayrıcalıklarından yararlanabilirsiniz. Ayrıcalıklar geçerlilik süresi sonunda sona erecektir.',
  noble_introduce_4: '4. Günlük Coins Talimatları',
  noble_introduce_text4: 'Aristokrasiyi coins ile satın aldıktan sonra, her gün hediye olarak coins almaya hak kazanırsınız. Her gün giriş yaptıktan sonra coins alabilirsiniz. Eğer o gün giriş yapmazsanız o günkü coinsleri alamayacaksınız. Aristokrasiyi başkalarına hediye ettiğinizde, alıcı günlük coins alma hakkını kazanacaktır.',
  noble_introduce_5: '5. Sürekli Yükleme İndirimleri',
  noble_introduce_text5: 'Aristokrat statünün kazanıldığı dönemde ve Aristokrat statünün sona ermesinden sonraki 10 gün içerisinde aynı Aristokrat statü daha uygun bir fiyata tekrar satın alınabilir. Bu plan otomatik değildir ve yürürlüğe girmesi için manuel olarak satın almanız gerekir.',
  noble_introduce_6: '6. Aristokrasi Yükseltme',
  noble_introduce_text6: 'Zaten Aristokrat statünüz varsa, daha yüksek seviye Aristokrasi satın alabilir ve denk gelen avantajlardan doğrudan yararlanabilirsiniz. Mevcut aristokrasinin kalan günleri, kalan değere göre en son seviyeye birleştirilecek ve yeni Aristokratik seviyenin geçerlilik süresi uzatılacak.',
  noble_introduce_7: '7. Aristokrasi Hediye Etmek',
  noble_introduce_text7: 'Aristokrasiyi başka bir kişiye hediye ederken, orijinal fiyattan kişiye verilecek ve alıcı, günlük coinslerden ve seviyeye karşılık gelen diğer haklardan yararlanacaktır. Alıcının zaten Aristokrat statüsü varsa, doğrudan yüksek seviyeli Aristokrat statüsünden yararlanacak ve diğer seviyeler ilgili süreye göre dönüştürülecektir.',
  vip_introduce_title: 'VIP Kurallarına Giriş',
  vip_introduce_welcome: 'VIP sistemimize hoş geldiniz! VIP daha fazla ayrıcalıktan yararlanmanızı sağlayacaktır. Aşağıda VIP sistemini açıklayıcı bir tanıtım yer almaktadır:',
  vip_introduce_1: '1. VIP nedir?',
  vip_introduce_text1: 'VIP bir kullanıcı gelişim sistemidir. Şu anda VIP1-VIP16 olmak üzere 16 seviye bulunmaktadır. VIP seviyesi ne kadar yüksek olursa, o kadar çok haktan yararlanırsınız ve işlevler de o kadar çeşitli olur.',
  vip_introduce_2: '2.Nasıl VIP olunur?',
  vip_introduce_text2: 'Coins yükleyerek VIP deneyim puanı kazanabilirsiniz, 1 coins = 1 deneyim puanı. Ne kadar çok coins yüklerseniz, o kadar çok deneyim puanı kazanırsınız ve VIP daha yüksek bir seviyeye yükselir.',
  vip_introduce_3: '3.VIP Geçerlilik Süresi',
  vip_introduce_text3: 'VIP statüsü alındıktan sonra kalıcı olarak geçerli olur ve VIP deneyim puanları kalıcı olarak birikir ve silinmez. Deneyim puanları arttıkça daha yüksek bir VIP seviyesine yükselecek ve daha fazla seviye avantajından yararlanacaksınız.',
  vip_introduce_4: '4. VIP Aksesuar Kullanımı',
  vip_introduce_text4: 'Elde ettiğiniz VIP seviyesi aksesuarları her zaman sırt çantanızda sabit kalacak ve istediğiniz zaman kullanılabilecektir. Yükseltme yaptığınızda, önceden elde ettiğiniz düşük seviyeli aksesuarlar yine kullanılabilir.',
  svip_introduce_title: 'SVIP Kurallarına Giriş',
  svip_introduce_welcome: 'SVIP sistemimize hoş geldiniz! VIP daha fazla ayrıcalıktan yararlanmanızı sağlayacaktır. Aşağıda SVIP sistemini açıklayıcı bir tanıtım yer almaktadır:',
  svip_introduce_text43: "Olağan bir ay içinde SVIP deneyim puanı oluşturulmazsa, SVIP seviyesi bir sonraki ay 3 seviye düşecektir. (Örneğin: Mayıs başladığında SVIP4 olursunuz ve Mayıs boyunca hiçbir deneyim puanı oluşturulmaz, ardından Haziran SVIP1'den başlayarak 3 seviye düşecektir)",
  svip_introduce_5: "5. SVIP'in Hakları ve Faydaları",
  svip_introduce_text5: 'SVIP seviyesi ne kadar yüksek olursa, o kadar fazla haktan yararlanırsınız. Seviyeniz değiştiğinde, yeni seviyenin avantajlarından yararlanıp o seviyeye denk gelen aksesuar ödüllerini alabileceksiniz. Kişiselleştirilmiş aksesuarlar yükseltilirken veya kullanımdayken aktif olarak müşteri hizmetleriyle iletişime geçmeniz ve kişiselleştirme gereksinimlerini dağıtılmadan önce belirtmeniz gerekir. Ancak, seviyeniz düşerse verilmeyecektir. Seviye arttıkça ücretsiz mesajların ve ücretsiz aramaların miktarı artacaktır. İlgili miktar, seviyeyi yükselttiğinizde veya koruduğunuzda verilecektir, ancak seviyeyi düşürdüğünüzde verilmeyecektir. Her seviyede dağıtılan miktarlar aşağıdaki gibidir:',
  svip_table1: 'SVIP Seviyesi',
  vip_table1: 'VIP Seviyesi',
  svip_table2: 'Ücretsiz Mesaj',
  svip_table3: 'Ücretsiz Aramalar',
  Anonymity_name: 'Anonim',
  Noble_Change_2: "Satın Alma Koşulları: Daha düşük rütbeler daha yüksek rütbeleri satın alabilir, ancak daha yüksek rütbeler daha düşük rütbeleri satın alamaz. Örneğin, halihazırda bir Kont iseniz yalnızca Marki, Dük veya Kral'ı satın alabilirsiniz.",
  Noble_Change_3: 'Dönüşüm Mantığı: Eğer zaten bir aristokrat iseniz, başka bir aristokrat rütbesi satın aldıktan sonra, eski aristokrat rütbesinin kalan geçerlilik süresi, coins fiyat oranına göre yeni aristokrat rütbesine dönüştürülecek ve eski aristokrat rütbesi geçersiz hale gelecektir.',
  Noble_Change_4: 'Geçerlilik Dönemi Hesaplama Formülü: Yeni satın alınan aristokrat rütbenin geçerlilik süresi = mevcut aristokrat rütbenin kalan gün sayısı * (mevcut aristokrat rütbe fiyatı / satın alınan aristokrat rütbe fiyatı) + 30, son gün sayısı aşağı yuvarlanır.',
  Noble_Change_5: "Örnek: Şu anda bir Vikont iseniz (99990 coins/ay), 13 gününüz kaldı ve bugün Kont (199990 coins/ay) satın aldıysanız, ödeme yapıldıktan sonra Kont'un geçerlilik süresi 13 * (99990/199990) olacaktır. + 30 = 6,5 aşağı yuvarlanmış + 30 = 36 gün.",
  rich_list: 'Zenginlik Sıralaması',
  lucky_list: 'Şans Sıralaması',
  lucky_daily: 'Günlük',
  lucky_weekly: 'Haftalık',
  lucky_faq1: 'Nasıl Oynanır',
  lucky_faq2: 'Oyun Kuralları',
  lucky_faq3: '1. Şanslı bir hediye verdikten sonra 1-1000 katı kadar coins ödülü kazanma şansınız olur;',
  lucky_faq4: '2. Kombo veya Toplu olarak ne kadar çok şanslı hediye gönderirseniz, kazanma şansınız o kadar artar;',
  lucky_faq5: '3. Coins ödülleri hesap bakiyesine gerçek zamanlı olarak dağıtılacak ve kazanma ayrıntıları şanslı hediye kaydında görüntülenebilecek;',
  lucky_faq6: '4. Her şanslı hediye verildiğinde, Yayıncılar da belirli oranda elmas alacaktır.',
  lucky_faq7: 'Sıralama Kuralları',
  lucky_faq8: '1. İstatistik Kuralları',
  lucky_faq9: 'Günlük ve haftalık oyuna katılarak en çok coins harcayan on kullanıcı Zenginlik Sıralamasında, en yüksek kazanma katlarına sahip on kullanıcı ise Şans Sıralamasında yer alacak.',
  lucky_faq10: '2. İstatistiksel Döngü',
  lucky_faq11: "Günlük sıralama: her gün 0:00 ile 24:00'a kadar",
  lucky_faq12: "Haftalık sıralama: her Pazartesi 0:00'dan Pazar 24:00'a kadar",
  lucky_faq13: '3. Sıralama Ödülleri',
  lucky_faq14: "Günlük ve haftalık sıralamada ilk 10'a girenler aşağıda gösterildiği gibi ödüller alacaktır.",
  lucky_faq15: 'Günlük Sıralama Ödülleri',
  lucky_faq16: 'Sıralama',
  lucky_faq17: 'Haftalık Sıralama Ödülleri',
  Charge_Same_1: 'Yakın zamanda bu kullanıcıya başarılı bir şekilde aynı miktarda yükleme gönderdin. Tekrar işlem yapmak ister misin?',
  Charge_Same_2: 'Ödemeyi İptal Et',
  Charge_Same_3: 'Ödemeye Devam Et',
  Noble_Change_2: "Satın Alma Koşulları: Daha düşük rütbeler daha yüksek rütbeleri satın alabilir, ancak daha yüksek rütbeler daha düşük rütbeleri satın alamaz. Örneğin, halihazırda bir Kont iseniz yalnızca Marki, Dük veya Kral'ı satın alabilirsiniz.",
  Noble_Change_3: 'Dönüşüm Mantığı: Eğer zaten bir aristokrat iseniz, başka bir aristokrat rütbesi satın aldıktan sonra, eski aristokrat rütbesinin kalan geçerlilik süresi, coins fiyat oranına göre yeni aristokrat rütbesine dönüştürülecek ve eski aristokrat rütbesi geçersiz hale gelecektir.',
  Noble_Change_4: 'Geçerlilik Dönemi Hesaplama Formülü: Yeni satın alınan aristokrat rütbenin geçerlilik süresi = mevcut aristokrat rütbenin kalan gün sayısı * (mevcut aristokrat rütbe fiyatı / satın alınan aristokrat rütbe fiyatı) + 30, son gün sayısı aşağı yuvarlanır.',
  Noble_Change_5: "Örnek: Şu anda bir Vikont iseniz (99990 coins/ay), 13 gününüz kaldı ve bugün Kont (199990 coins/ay) satın aldıysanız, ödeme yapıldıktan sonra Kont'un geçerlilik süresi 13 * (99990/199990) olacaktır. + 30 = 6,5 aşağı yuvarlanmış + 30 = 36 gün.",
  Registr_optimization_1: 'Kayıt ve giriş sorunları',
  AnchorCenter_biList_interaction_text1: 'Etkileşim',
  AnchorCenter_biList_interaction_text2: 'Başlatılan Sohbetler (UV)',
  AnchorCenter_biList_interaction_text3: 'Yanıt Oranı (UV)',
  AnchorCenter_biList_interaction_text4: 'Yanıt Oranı (PV)',
  AnchorCenter_biList_interaction_text5: 'Dönüş Oranı (UV)',
  AnchorCenter_biList_interaction_text6: 'Alınan Mesajlar (UV)',
  AnchorCenter_biList_interaction_text7: 'Yanıtlanan Mesajlar (UV)',
  AnchorCenter_biList_interaction_text8: 'Yanıt Oranı (UV)',
  AnchorCenter_biList_interaction_text9: 'Gönderilen Mesajlar (UV)',
  AnchorCenter_biList_interaction_text10: 'Yanıtlanan Mesajlarınız (UV)',
  AnchorCenter_biList_interaction_text11: 'Yanıt Oranı (UV)',
  AnchorCenter_biList_interaction_text12: 'Gönderilen Mesajlar (PV)',
  AnchorCenter_biList_interaction_text13: 'Yanıtlanan Mesajlarınız (PV)',
  AnchorCenter_biList_interaction_text14: 'Yanıt Oranı (PV)',
  AnchorCenter_biList_interaction_Popup1: "x' kullanıcıdan mesaj aldınız",
  AnchorCenter_biList_interaction_Popup2: "Size mesaj gönderen 'x' kişiden 'n' tanesine yanıt verdiniz",
  AnchorCenter_biList_interaction_Popup3: "Örnek: 100 kişiden mesaj aldınız ve bunların 90'ına etkili bir şekilde yanıt verdiniz. Etkili yanıt oranınız (UV) = 90/100 = %90",
  AnchorCenter_biList_interaction_Popup4: 'Mesaj Yanıt Yüzdesi (UV)',
  AnchorCenter_biList_interaction_Popup5: 'Etkili Yanıt Oranı: Yalnızca zaman aşımından önce yanıtlanan kullanıcıları sayar',
  AnchorCenter_biList_interaction_Popup6: 'Tamam',
  AnchorCenter_biList_interaction_Popup7: "x' kişiye mesaj gönderdiniz",
  AnchorCenter_biList_interaction_Popup8: "Mesajlaştığınız 'x' kişiden 'n' tanesi size yanıt verdi",
  AnchorCenter_biList_interaction_Popup9: 'Yanıtlayan Kullanıcıların Yüzdesi (UV)',
  AnchorCenter_biList_interaction_Popup10: 'Örnek: 100 kişiye mesaj gönderdiniz ve 50 tanesi cevap verdi. Etkili yanıt oranınız (UV) = 50/100 = %50',
  AnchorCenter_biList_interaction_Popup11: 'Etkili Yanıt Oranı: Yalnızca zaman aşımından önce alınan yanıtları sayar',
  AnchorCenter_biList_interaction_Popup12: "x' mesaj gönderdiniz",
  AnchorCenter_biList_interaction_Popup13: "Gönderdiğiniz 'x' mesajdan 'n' tanesine etkili bir şekilde yanıt verildi",
  AnchorCenter_biList_interaction_Popup14: 'Yanıtlanan Mesajlarınızın Yüzdesi (PV)',
  AnchorCenter_biList_interaction_Popup15: "Örnek: 100 mesaj gönderdiniz ve bunların 70'i etkili bir şekilde yanıtlandı. Etkili yanıt oranınız (PV) = 70/100 = %70",
  AnchorCenter_biList_interaction_Popup16: 'Etkili Yanıt Oranı: Yalnızca zaman aşımından önce alınan yanıtları sayar',
  program_list_24_ios: 'silin',
  withdraw_text_26: 'Lütfen ödeme bilgilerini doğrulayın. Yanlış bilgiler, çekim işleminin başarısız olmasına neden olacak ve ek ücretler kullanıcı tarafından karşılanacaktır.',
  withdraw_text_29: 'İpucu:',
  withdraw_text_43: 'Bu ödeme yöntemi için tamamlanmamış siparişler bulunmakta ve geçici olarak değiştirilemez veya silinemez.',
  withdraw_text_44: 'Başarıyla gönderildi!',
  payment_bind_2: 'Papara Numaranız',
  payment_bind_2_GPAY: 'Banka İbanı(TL), iban yazılırken arada kalan boşlukları silmelisiniz',
  payment_bind_3: 'Banka adı',
  payment_bind_4: 'İsim',
  payment_bind_5: 'Mail',
  payment_bind_6: 'Adres',
  payment_bind_7: '10 hane veya PL artı 10 hane',
  payment_bind_8: 'TR + 24 hane ile başlar',
  payment_bind_9: '{n} gerekir',
  payment_bind_10: 'Silme başarılı',
  payment_bind_11: 'İsim:',
  payment_bind_12: 'Soyisim:',
  Tvwall_gift_9: 'TV duvarında görüntülenecek 2.000 coinse eşit veya daha fazla değere sahip hediye istek listelerini tamamlayın',
  Tvwall_gift_10: 'Giriş kilit ekranı koşulları:',
  Tvwall_gift_32: 'Hediye vermek televizyonda gösterilmeli mi?',
  Tvwall_gift_33: '(Hemen yürürlüğe girmesi için seçmek için tıklayın)',
  Tvwall_gift_11: 'Ekranı 10 saniye kilitleyin: Bir istek hediyesi tamamlandı ve istek hediyesinin değeri 2000 coins ile 9999 coins arasında;',
  Tvwall_gift_12: '20 saniye boyunca ekranı kilitle: Bir istek hediyesi tamamlandı ve istek hediyesinin değeri 10.000 coins ile 49.999 coins arasında;',
  Tvwall_gift_13: '60 saniye boyunca ekranı kilitle: Bir istek hediyesi tamamlandı ve istek hediyesinin değeri 50.000 coinsten fazla',
  Tvwall_gift_14: 'Global TV Duvarında görünme koşulları:',
  Tvwall_gift_15: 'Sıradan kayıt: Bir istek hediyesi tamamlandı ve istek hediyesinin değeri 2000 coins ile 9999 coins arasında.',
  Tvwall_gift_16: 'Lüks rekor: Bir istek hediyesi tamamlandı ve istek hediyesinin değeri 10.000 coinse eşit veya daha fazla',
  Tvwall_gift_17: 'TV Duvarında',
  Tvwall_gift_18: 'TV Duvarında Anonim',
  Tvwall_gift_19: 'TV Duvarında Değil',
  Admob_text7: 'Görev hazırlanıyor',
  Admob_text8: 'Video henüz hazır değil',
  EXP: 'EXP',
  Experience_Card_Statement: '*Bu avantaj deneyim kartlarında yoktur',
  Official_website_payment_1: 'SOYO Çevrimiçi Yükleme',
  Official_website_payment_2: 'Yükleme Kimliği',
  Official_website_payment_3: 'Kimliği girin',
  Official_website_payment_4: 'Kimlik nedir?',
  Official_website_payment_5: 'Lütfen önce hesabın gizliliğini ve güvenliğini korumak için kontrol edin.',
  Official_website_payment_6: 'Sorgu',
  Official_website_payment_7: 'Kullanıcı Bildirimi',
  Official_website_payment_8: '1. Gereksiz anlaşmazlıkları önlemek için resmi olmayan veya yetkisiz web siteleri aracılığıyla SOYO coins satın almayın.',
  Official_website_payment_9: '2. Diğer web sitelerinde, iletişim kanallarında veya iletişim gruplarında yayınlanan resmi olmayan bilgilere güvenmeyin. Tüm etkinlik bilgileri resmi web sitesi veya sistem içi bildirim aracılığıyla resmi duyuruya tabidir.',
  Official_website_payment_10: '3. Çevrimdışı işlemler desteklenmez.',
  Official_website_payment_11: '4. Hesap bilgilerinizi hiçbir nedenle veya hiçbir yöntemle başkasına ifşa etmeyin.',
  Official_website_payment_12: '5. SOYO, Kullanıcılar ile üçüncü taraf arasındaki herhangi bir anlaşmazlıktan sorumlu tutulamaz.',
  Official_website_payment_13: '6. Yükleme ile ilgili herhangi bir sorun varsa lütfen müşteri hizmetleriyle iletişime geçin veya bize e-posta gönderin.',
  Official_website_payment_14: 'Kara Para Aklamayı Önleme',
  Official_website_payment_15: '1. Kullanıcı, tarafımızca sağlanan hizmetleri kullanmak için yasal bir kimliğe ve kapasiteye sahip olduğundan ve herhangi bir suç faaliyetine katılmayacağından veya bunlarla ilişkilendirilmeyeceğinden emin olmalıdır.',
  Official_website_payment_16: '2. Kullanıcı hesabı yalnızca sizin kullanımınıza yöneliktir ve yalnızca size aittir. Hesabınızı herhangi bir üçüncü tarafa devredemez, kiralayamaz veya başka şekilde elden çıkaramazsınız. 3.Kullanıcı, yüklemeler için kullanılan tüm paranın yasal yollardan elde edildiğinden ve hiçbir zaman suç teşkil eden bir faaliyet veya davranışla ilişkilendirilmediğinden emin olmalıdır.',
  Official_website_payment_17: '3.Kullanıcı, yükleme için kullanılan tüm paranın yasal yollarla elde edildiğinden ve hiçbir zaman suç faaliyeti veya davranışıyla ilişkilendirilmediğinden emin olmalıdır.',
  Official_website_payment_18: 'Ödeme Yöntemi',
  Official_website_payment_19: 'Şu anda {%s} ödeme kanalı kullanılıyor',
  Official_website_payment_20: 'Yükleme Seçeneği',
  Official_website_payment_21: 'Değiştir',
  Official_website_payment_22: 'Hesap Kimliği mevcut değil!',
  Official_website_payment_23: 'Henüz desteklenen ödeme yöntemi yok',
  Official_website_payment_24: 'İstediğiniz ödeme yöntemini geri bildirimde bulunun',
  Official_website_payment_25: 'İşlem sonuçları sorgulanıyor',
  Official_website_payment_26: 'Yükleme sonucu onaylanacak',
  Official_website_payment_27: 'Yükleme sonucu gecikebilir. Hesap yükleme kaydını kontrol etmek için lütfen uygulamaya girin. Hesap uzun süre gelmezse lütfen müşteri hizmetleriyle iletişime geçin.',
  Official_website_payment_28: 'Yenile',
  Official_website_payment_29: 'Yükleme başarısız',
  Official_website_payment_30: 'Yardım bilgileri:',
  Official_website_payment_31: '1. Lütfen kişisel bilgiler, kredi kartı numarası, geçerlilik süresi, CVV kodu vb. dahil olmak üzere tutarın ve ödeme yönteminin doğru olup olmadığını onaylayın.',
  Official_website_payment_32: '2. Lütfen hesap bakiyesinin yeterli olup olmadığını kontrol edin.',
  Official_website_payment_33: '3. Ağ bağlantısının kararlı olup olmadığını kontrol edin. Bazen ağ sorunları ödeme başarısızlığına neden olabilir.',
  Official_website_payment_34: '4. Başka ödeme yöntemlerine geçmeyi deneyin veya daha sonra yüklemeyi deneyin.',
  Official_website_payment_35: '5. Sorun hala çözülmediyse, daha fazla yardım için yükleme platformunun müşteri hizmetleriyle iletişime geçmeniz önerilir.',
  Official_website_payment_36: 'Yükleme başarılı',
  Official_website_payment_37: 'Hesap yükleme kaydını görüntülemek için lütfen uygulamaya girin',
  Official_website_payment_38: 'Bağlantı zaman aşımı başarısız',
  Official_website_payment_39: 'Bağlantının süresi doldu. Lütfen Uygulamada ödeme bağlantısını yeniden oluşturun.',
  payment_bind_13: 'Contract Address',
  payment_bind_14: 'Epay Email',
  payment_bind_15: 'Note: TRC-20 protocol',
  payment_bind_16: 'Note: Ensure your Epay account is verified',
  payment_bind_17: 'Contract address starts with T',
  payment_bind_18: 'Cannot exceed 40 characters',
  payment_bind_19: 'Must match your Epay account email',
  payment_bind_20: 'Enter email address',
};

export default TR;
